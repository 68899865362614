:global {
    body {
        overflow: hidden;
        background-image: url(../img/banner.jpg) !important;
        background-size: auto 100vh !important;
    }
}

.kiosk {
    :global {
        .kiosk-logo {
            margin: 4vh 0 4vh 0;
            min-width: 12vw;
        }

        .ui.cards > .ui.card {
            zoom: 1.6;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .ui.cards > .ui.card > .content > .header:not(.ui) {
            font-size: x-large;
        }

        .ui.card.rego-search-card { 
            min-width: 50vw;
            .rego-input {
                width: 100%;
            }
        }

        .entry-details {
            font-size: x-large;
        }

        .ui.card.rego-search-result-card {
            min-width: 55vw;
            .button {
                min-width: 18vw;
            }
            .ui.huge.label {                
                position: relative;     
                margin-right: 0.3em;
                line-height: 1.3em;
                i.print.icon {
                    margin-right: 0.3em;
                }
            }
            .ui.fitted.checkbox {
                font-size: 1.4em;                
                margin-right: 0.2em;
                position: relative;     
                label:before, label:after {
                    width: 1em;
                    height: 1em;
                    font-size: 1em;
                    top: -0.15em;                    
                }                
                label:after {
                    font-size: 0.9em;
                    top: 0.03em;
                    left: 0.05em;
                }
            }
        }

        .ui.card{
            min-width: 40vw;
        }

        .ui.card > .content, .ui.cards > .card > .content {
            padding: 3em;            
        }

        .ui.card > .content, .ui.cards > .card {
            background-color: rgba(255,255,255,0.6);
            box-shadow: none;
        }

        .ui.card > .content, .ui.cards > .card > .extra.content > button.button {
            background-color: #145571;
            color: lightgrey;
        }
    }
}